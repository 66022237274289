/* body {
    background: url("https://xva.asia/img/page-bg.jpg") no-repeat;
    background-size: cover;
} */

.claim_claimContainer {
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
}

.content_content {
    background: #d1d3d4;
    padding: 2px;
    margin-bottom: 20px;
}

.content_inner {
    height: 78px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 24px;
    min-height: 40px;
}

.content_inner p {
    margin: 0;
}

.claim-informations_claimable {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    margin-bottom: 96px;
    text-align: center;
}

.claim-informations_dateContainer {

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
}

.disabled {
    opacity: 0.1;
}

.connect_button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    padding: 50px;
    background: rgba(0,0,0,0.75);
    border-radius: 30px;
}

.browse-time-left ul {
    padding: 0;
	margin: 0px;
    list-style: none;
	display: flex;
	justify-content: center;
}
.browse-time-left ul > li {
	float: left;
	margin-right: 10px;
	padding-right: 10px;
	font-size: 12px;
	text-transform: uppercase;
	color: #CCC;
}
.browse-time-left ul > li:last-child {
	margin-right: 0px;
	padding-right: 0px;
}
.browse-time-left ul > li > span {
	font-size: 26px;
	font-weight: 700;
	display: block;
	font-family: "Mulish", sans-serif;
	line-height: normal;
	color: #FFF;
}